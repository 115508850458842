import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'

const Image = styled(GatsbyImage)`
  ${tw`rounded-sm`};
`

const Wrapper = styled.article`
  ${tw`w-1/2 md:w-1/4 lg:w-1/4 pr-8 mb-6`};
`

const ImageContainer = styled.div`
  ${tw`w-full xl:w-full`};
`

const Certification = ({ cert }) => (
  <Wrapper>
    {cert.image && (
      <ImageContainer>
        <a className="leading-normal py-0" href={cert.link} target="_blank" rel="nofollow">
          <Image
            alt={cert.name}
            title={cert.name}
            image={cert.image.gatsbyImageData}
          />
        </a>
      </ImageContainer>
    )}
  </Wrapper>
)

export default Certification
