import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import tw, { styled } from 'twin.macro'

import SEO from '../components/seo'

import ArticleCard from '../components/article-card'
import Certification from '../components/certification'
import PreviewCase from '../components/case-preview'
import SectionHeading from '../components/section-header'

const Hero = styled.div`
  ${tw`w-full md:max-w-md xl:max-w-lg md:h-screen-60 flex items-center my-10 md:my-0`};
`
const Heading = styled.h1`
  ${tw`text-2xl font-semibold md:text-4xl md:font-normal my-4 md:my-6`};
`
const Subtitle = styled.h2`
  ${tw`text-lg md:text-xl w-full my-4 md:my-6`};
  font-weight: 400;
`
const Section = styled.section`
  ${tw`mb-12`};
`
const Projects = styled.div`
  ${tw`flex flex-wrap my-4 font-sans text-base text-grey`};
`

const Articles = styled.div`
  ${tw`flex flex-wrap my-4`};
`

const Certifications = styled(Articles)`
  ${tw`flex-wrap-reverse flex-row-reverse`}
`;

class IndexPage extends React.Component {
  render() {
    const [siteSettings] = get(this, 'props.data.allContentfulSettings.edges')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const cases = get(this, 'props.data.allContentfulPortfolioEntry.edges')
    const certifications = get(this, 'props.data.allContentfulCertificationItem.edges')
    return (
      <>
        <SEO
          title="James Does Digital | Software Developer | Web, Mobile & Cloud"
          keywords={[
            `javascript`,
            `blog`,
            `ui`,
            `aws`,
            `cloud`,
            `react`,
            `node`,
            `ruby`,
          ]}
        />
        <Hero>
          <div>
            <Heading>{siteSettings.node.heading}</Heading>
            <Subtitle>{siteSettings.node.subheading}</Subtitle>
          </div>
        </Hero>
        <Section id="latestArticles">
          <SectionHeading heading="Recent Articles" callToActionText="See all" callToActionLink='/articles' />
          <Articles>
            {posts.map(({ node }) => {
              return <ArticleCard article={node} key={node.slug} />
            })}
          </Articles>
        </Section>
        <Section id="myCertifications">
          <SectionHeading heading="Certifications" callToActionText="See all" callToActionLink='/certifications' />
          <Certifications>
            {certifications.map(({ node }) => {
              return <Certification cert={node} key={node.name} />
            })}
          </Certifications>
        </Section>
        <SectionHeading heading="Selected Projects" callToActionText="See all" callToActionLink='/portfolio' />
        <Section id="selectedWork">
          <Projects>
            {cases.map(({ node }) => {
              return <PreviewCase entry={node} key={node.slug} />
            })}
          </Projects>
        </Section>
      </>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulSettings(
      filter: { contentful_id: { eq: "gyjLyhLGCv40sDcbd38r9" } }
    ) {
      edges {
        node {
          heading
          subheading
        }
      }
    }
    allContentfulPortfolioEntry(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
      filter: { featured: { ne: false }, draft: { ne: true } }
    ) {
      edges {
        node {
          title
          clientName
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          listImage {
            gatsbyImageData(
              quality: 90
              width: 1200
              height: 600
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
          projectColor
          description
        }
      }
    }
    allContentfulCertificationItem(
      sort: { fields: [date], order: DESC }
      filter: { contentful_id: { ne: "5asKjcFo9PIZV0zTStG9PT" } }
      limit: 8
    ) {
      edges {
        node {
          name
          date
          link
          image {
            gatsbyImageData(
              quality: 95
              width: 450
              height: 450
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
    allContentfulBlogPost(
      filter: { draft: { ne: true } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          title
          titleAbridged
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(
              quality: 99
              width: 400
              height: 225
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 75)
            }
          }
          description
        }
      }
    }
  }
`
