import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'

const SectionHeader = styled.h4`
  ${tw`text-xl font-semibold border-l-0 border-r-0 relative
  border-t-0 mb-6 border-b-2 border-grey-lighter dark:border-grey-dark border-solid`};
`

const SectionHeaderText = styled.span`
  ${tw`font-sans border-l-0 border-r-0 border-t-0 border-b-2 pb-4 border-blue-lighter border-solid inline-block`};
  font-size: inherit;
  margin-bottom: -2px;
`

const CallToAction = styled(Link)`
  text-align: right;
  ${tw`font-sans text-sm absolute font-normal right-0 bottom-1/2`};
  transform: translateY(25%);
  text-decoration: none;
  box-shadow: none;
`

const SectionHeading = ({ heading, callToActionText, callToActionLink }) => (
  <SectionHeader>
    <SectionHeaderText>{heading}</SectionHeaderText>
    {callToActionText && callToActionLink && (
      <CallToAction className="leading-normal py-0" to={callToActionLink}>
        {callToActionText}
      </CallToAction>
    )}
  </SectionHeader>
)

SectionHeading.propTypes = {
  heading: PropTypes.string,
  callToActionText: PropTypes.string,
  callToActionLink: PropTypes.string,
}

export default SectionHeading
