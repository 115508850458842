import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'
import BlockRevealAnimation from 'react-block-reveal-animation'
import { useInView } from 'react-intersection-observer'

const Wrapper = styled.div`
  ${tw`w-full flex flex-grow flex-wrap md:flex-nowrap items-center mb-12 md:mb-20`};
`

const TextContainer = styled.div`
  ${tw`w-full md:w-2/5 md:mx-6 xl:px-12`};
`
const Title = styled.h3`
  ${tw`leading-normal font-sans font-bold py-0 mb-2 text-xl`};
`
const Description = styled.p`
  ${tw`text-base mt-2 mb-3 dark:text-white`};
`
const Tags = styled.div`
  ${tw`text-sm text-blue dark:text-blue-dark font-sans font-semibold tracking-wide uppercase`};
`
const Concept = styled.span`
  ${tw`text-indigo`};
`
const ImageWrapper = styled.div`
  ${tw`w-full md:w-3/5 block relative lg:mx-6`};
`
const ImageWrapperInner = styled(BlockRevealAnimation)`
  ${tw`lg:w-4/5 xl:w-3/4 mx-auto block z-20`};
`
const ImageWrapperInnerPreload = styled.div`
  ${tw`lg:w-3/4 xl:w-2/3 mx-auto block opacity-0`};
`
const ImageBackground = styled.div`
  ${tw`h-full w-full block absolute top-0 z-10 py-10`};
`
const ImageBackgroundInner = styled.div`
  ${tw`block h-full w-full bg-grey-lighter dark:bg-[rgba(22,30,46,0.9)] rounded-sm`};
`
const Image = styled(GatsbyImage)`
  ${tw`object-cover mb-4 rounded-sm w-full relative z-20 shadow-lg`};
`

const PreviewCase = ({ entry }) => {
  const [ref, inView] = useInView({
    /* Optional params */
    threshold: 0.35,
    triggerOnce: true,
  })

  return (
    <Wrapper className="caseContainer">
      <ImageWrapper className="imageWrapper">
        <div ref={ref}>
          {inView === true ? (
            <ImageWrapperInner
              color={entry.projectColor}
              delay={0}
              duration={0.6}
            >
              <Link
                className="leading-normal py-0"
                to={`/portfolio/${entry.slug}`}
              >
                <Image
                  alt={entry.title}
                  title={entry.title}
                  image={entry.listImage.gatsbyImageData}
                />
              </Link>
            </ImageWrapperInner>
          ) : (
            <ImageWrapperInnerPreload>
              <Image
                alt={entry.title}
                title={entry.title}
                image={entry.listImage.gatsbyImageData}
              />
            </ImageWrapperInnerPreload>
          )}
        </div>
        <ImageBackground>
          <ImageBackgroundInner />
        </ImageBackground>
      </ImageWrapper>
      <TextContainer className="TextContainer">
        <Title>
          <Link className="leading-normal py-0" to={`/portfolio/${entry.slug}`}>
            {entry.title}
          </Link>
        </Title>
        <Description>{entry.description}</Description>
        <Tags>
          {entry.tags.map((tag, i) => (
            <span className="mb-0" key={tag}>
              {tag}
              {i !== entry.tags.length - 1 && <span>&nbsp;·&nbsp;</span>}
            </span>
          ))}
          {entry.concept === true && <Concept>&nbsp;·&nbsp;Concept</Concept>}
        </Tags>
      </TextContainer>
    </Wrapper>
  )
}

export default PreviewCase
