import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'

const Image = styled(GatsbyImage)`
  ${tw`rounded-sm`};
`

const Wrapper = styled.article`
  ${tw`pr-2 w-full md:w-1/2 lg:w-1/3 md:pr-8 mb-6`};
`
const Title = styled.h3`
  ${tw`leading-normal text-lg font-semibold font-sans py-0 my-2 text-xl`};
`
const Description = styled.p`
  ${tw`my-2 text-base`};
`
const Date = styled.p`
  ${tw`my-2 font-semibold tracking-wide uppercase text-blue dark:text-blue-dark font-sans text-xs`};
`

const ImageContainer = styled.div`
  ${tw`w-full`};
`

const ArticleCard = ({ article }) => (
  <Wrapper>
    {article.heroImage && (
      <ImageContainer>
        <Link className="leading-normal py-0" to={`/articles/${article.slug}`}>
          <Image
            alt={article.title}
            title={article.title}
            image={article.heroImage.gatsbyImageData}
          />
        </Link>
      </ImageContainer>
    )}
    <Title>
      <Link className="leading-normal py-0" to={`/articles/${article.slug}`}>
        {article.titleAbridged || article.title}
      </Link>
    </Title>
    <Description>{article.body.childMarkdownRemark.excerpt}</Description>
    <Date>{article.publishDate}</Date>
  </Wrapper>
)

export default ArticleCard
